import type { NavigationMenuItem } from '@revolutionprep/types'

const menuItems = (): NavigationMenuItem[] => {
  return [
    {
      id: 'navItemDashboard',
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
      external: false
    },
    {
      id: 'navItemSchedule',
      title: 'Schedule',
      icon: 'mdi-calendar-month',
      to: '/schedule',
      external: false
    },
    {
      id: 'navItemProfile',
      title: 'Profile',
      icon: 'mdi-account-box-multiple',
      to: '/profile',
      external: false
    }
  ]
}

export default menuItems
